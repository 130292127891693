<template>
  <ServiceRadioWithMarketSegmentFormPart
    :choices="services"
    v-on="$listeners"
  />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import { heatPumpDefaultES } from 'chimera/heatPump/service'
import ServiceRadioWithMarketSegmentFormPart from 'chimera/all/components/form/parts/serviceMarketSegment/ServiceRadioWithMarketSegmentFormPart.vue'

export default {
  name: 'ServiceRadioFormPartEs',

  components: {
    ServiceRadioWithMarketSegmentFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      services: [
        new Selectable('Particular', 'Particular', heatPumpDefaultES.id),
        new Selectable('Empresa', 'Empresa', heatPumpDefaultES.id),
      ],
    }
  },
}
</script>
